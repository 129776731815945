<template>
  <div class="container Footprint">
    <userState />

    <div class="footprint-week">
      <ul class="week-list">
        <li v-for="(item, index) in weeks" :key="index" class="week-item" @click="clickWeek(item, index)">
          <div class="item-title" :class="selectWeek === item ? 'item-time-select' : 'item-time-default'">{{ item }}</div>
          <!-- <div class="item-time" :class="selectWeek === item ? 'item-time-select' : 'item-time-default'">
            {{ selectDay }}
          </div> -->
        </li>
      </ul>
    </div>

    <a-spin :spinning="isLoading">
      <div class="list">
        <template v-for="(item, index) in footprintList" :key="index">
          <GoodsCard class="db-mb10" :goods-info="item" />
        </template>
        <template v-for="(item, index) in 5 - footprintList.length % 5" :key="index">
          <div style="width: 244px; height: 392px;" />
        </template>
      </div>
    </a-spin>
  </div>
</template>

<script>
import userState from '@/views/user/components/userState'
import GoodsCard from '@/components/goodsCard'
import { getFooterPrintByTime } from '@/api/goods'
import { mapGetters } from 'vuex'
export default {
  name: 'Footprint',
  components: {
    userState,
    GoodsCard
  },
  data() {
    return {
      footprintList: [],
      selectWeek: 0,
      selectDay: 1,
      weeks: []
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'loading/isLoading'
    })
  },
  created() {
    this.changeWeek()
  },

  methods: {
    changeWeek() {
      const mowData = new Date().getDay()
      if (mowData === 0) {
        this.weeks = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
      }
      if (mowData === 1) {
        this.weeks = ['周二', '周三', '周四', '周五', '周六', '周日', '周一']
      }
      if (mowData === 2) {
        this.weeks = ['周三', '周四', '周五', '周六', '周日', '周一', '周二']
      }
      if (mowData === 3) {
        this.weeks = ['周四', '周五', '周六', '周日', '周一', '周二', '周三']
      }
      if (mowData === 4) {
        this.weeks = ['周五', '周六', '周日', '周一', '周二', '周三', '周四']
      }
      if (mowData === 5) {
        this.weeks = ['周六', '周日', '周一', '周二', '周三', '周四', '周五']
      }
      if (mowData === 6) {
        this.weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
      }
      this.selectWeek = this.weeks[6]
      this.getFooterPrintByTimeFun(new Date().getDay())
      // this.selectWeek = new Date().getDay()
      // let week = this.selectWeek
      // if (this.selectWeek === 0) week = 7
      // this.getFooterPrintByTimeFun(week)
    },
    clickWeek(item, index) {
      this.selectWeek = item
      let num = 0
      if (item === '周日') num = 0
      if (item === '周一') num = 1
      if (item === '周二') num = 2
      if (item === '周三') num = 3
      if (item === '周四') num = 4
      if (item === '周五') num = 5
      if (item === '周六') num = 6
      this.footprintLists = []
      this.getFooterPrintByTimeFun(num)
    },
    getFooterPrintByTimeFun(weekIndex) {
      this.footprintList = []
      const data = {
        weekIndex
      }
      getFooterPrintByTime(data).then(res => {
        this.footprintList = res.data || []
      })
    }
  }
}
</script>
<style lang='less' scoped>
.Footprint {
  .footprint-week {
    width: 100%;
    // height: 133px;
    height: 98px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    padding: 24px;
    margin-bottom: 40px;

    .week-list {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .week-item {
        width: 50px;
        display: flex;
        flex-direction: column;

        .item-title {
          // font-size: 16px;
          // font-weight: bold;
          // line-height: 21px;
          // color: #3B6652;
          // text-align: center;
          // margin-bottom: 16px;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          font-size: 18px;
          font-weight: bold;
          line-height: 44px;
          text-align: center;
          margin: auto;
          cursor: pointer;
        }
        .item-time {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          font-size: 18px;
          font-weight: bold;
          line-height: 44px;
          text-align: center;
          margin: auto;
          cursor: pointer;
        }
        .item-time-default {
          background: #F8F8F8;
          color: #3B6652;
        }
        .item-time-select {
          background: #3B6652;
          color: #FFFFFF;
        }
      }
    }
  }
  .list {
    margin-top: 16px;
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
</style>
