<template>
  <div class="container">
    <div class="UserState">
      <div class="user-info">
        <div class="portrait">
          <!-- <img src="" alt="portrait"> -->
          <upload :img="userInfo.header" @sendUrl="getUrlFun" />
        </div>
        <div class="user-info-msg">
          <div class="msg-userName">你好，{{ userNames }}！</div>
          <div class="msg-operation">
            <span>  <a href="">升级企业账户</a></span>
            <span><a href="javascript:;" @click="logout">退出账户</a></span>
          </div>
        </div>
        <div class="user-info-state"><span>个人账户</span></div>
      </div>

      <div class="user-fun">

        <a-tooltip placement="bottom">
          <template #title>帮助</template>
          <div class="user-fun-item" @click="changeFun">
            <img src="@/assets/images/icons/icon-help2.png" alt="help">
          </div>
        </a-tooltip>
        <a-tooltip placement="bottom">
          <template #title>优惠卷</template>
          <div class="user-fun-item">
            <img src="@/assets/images/icons/icon-coupon2.png" alt="coupon">
          </div>
        </a-tooltip>
        <a-tooltip placement="bottom">
          <template #title>足迹</template>
          <div class="user-fun-item" @click="toOther('footprint')">
            <img src="@/assets/images/icons/icon-footprint2.png" alt="footprint">
          </div>
        </a-tooltip>
        <a-tooltip placement="bottom">
          <template #title>收藏</template>
          <div class="user-fun-item" @click="toOther('Collection')">
            <img src="@/assets/images/icons/icon-Collection2.png" alt="Collection">
          </div>
        </a-tooltip>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import Cookies from 'js-cookie'
import upload from '@/components/upload'
import { updateMember } from '@/api/user'
import { loginOut } from '@/api/login'
export default {
  name: 'UserState',
  components: {
    upload
  },
  data() {
    return {
      mas: ''
    }
  },
  computed: {
    ...mapState({
      userNames: state => state.user.username,
      User_ID: state => state.user.id,
      userInfo: state => state.user.userInfo
    })
  },
  methods: {
    changeFun() {
      this.mas = Math.random() * 10
    },
    getUrlFun(url) {
      const data = {
        id: this.User_ID,
        header: url
      }
      updateMember(data).then(res => {
        this.$message.success('修改成功')
        this.$store.dispatch('user/getUserInfo')
      })
    },
    logout() {
      // Cookies.remove('MALL_TOKEN')
      // sessionStorage.removeItem('id')
      // location.reload() // 刷新
      loginOut().then(res => {
        if (res.code === 0) this.$message.success('账号已登出')
        this.$store.dispatch('car/clearShopCart')
        this.$store.dispatch('user/clearUserID')
        Cookies.remove('MALL_TOKEN')
        sessionStorage.removeItem('id')
        this.$router.replace({ path: '/' })
      })
    },
    Login() {
      this.$router.replace({ path: '/login' })
    },
    toOther(type) {
      if (type === 'Collection') this.$router.push({ path: '/user/collection' })
      if (type === 'footprint') this.$router.push({ path: '/user/footprint' })
    }
  }
}
</script>
<style lang='less' scoped>
.UserState {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 66px 0;

  .user-info {
    display: flex;
    align-items: center;

    .portrait {
      width: 84px;
      height: 84px;
      border: 1px solid;
      border-radius: 50%;
      margin-right: 34px;
      overflow: hidden;
      box-sizing: border-box;
    }
    .user-info-msg {
      margin-right: 12px;

      .msg-userName {
        color: #333333;
        font-weight: bold;
        font-size: 32px;
        margin-bottom: 16px;
      }

      .msg-operation {
        font-weight: 400;
        color: #666666;

        & > span:first-child {
          margin-right: 8px;
          padding-right: 8px;
          border-right: 1px solid #A2A2A2;
        }
      }
    }
    .user-info-state {
      width: 56px;
      height: 20px;
      background: @bg-color;
      font-size: 12px;
      color: #FFFFFF;
      font-weight: 400;
      text-align: center;
    }
  }

  .user-fun {
    display: flex;
    align-items: center;

    .user-fun-item {
      margin-left: 16px;
      cursor: pointer;
    }
  }
}
</style>
