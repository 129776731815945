import request from '@/utils/request'

// 手机短信接口
export function sendcode(params) {
  return request({
    url: '/api/auth/sms/snedcode',
    method: 'get',
    params
  })
}

// 账号密码注册
export function regist(params) {
  return request({
    url: '/api/auth/regist',
    method: 'post',
    params
  })
}
// 账号密码登陆
export function login(params) {
  return request({
    url: '/api/auth/login',
    method: 'post',
    params
  })
}
// 手机号登陆注册
export function phoneLoginRegist(params) {
  return request({
    url: '/api/auth/phoneLoginRegist',
    method: 'get',
    params
  })
}

// 登出
export function loginOut() {
  return request({
    url: '/api/auth/loginOut',
    method: 'post'
  })
}

