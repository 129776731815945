import request from '@/utils/request'

/**
 * @returns 上传图片
 */
export function policy() {
  return request({
    url: '/api/third/party/oss/policy',
    method: 'get'
  })
}
