<template>
  <div
    @mouseover="isUploadimgFun(true)"
    @mouseleave="isUploadimgFun(false)"
  >
    <a-upload
      list-type="picture-card"
      class="avatar-uploader"
      :show-upload-list="false"
      :data="dataObj"
      action="http://dbyc-kws.oss-cn-beijing.aliyuncs.com"
      :before-upload="beforeUpload"
      @change="handleChange"
    >
      <img v-if="imageUrl" :src="imageUrl" alt="avatar" class="avatar">
      <div v-show="isUploadImg" class="img-mask">
        <div>
          <loading-outlined v-if="loading" />
          <plus-outlined v-else />
          <div class="ant-upload-text">
            {{ imageUrl ? '修改' : '上传' }}头像
          </div>
        </div>
      </div>
    </a-upload>
  </div>
</template>

<script>
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { policy } from '@/api/common'
import { getUUID } from '@/utils'

export default {
  name: 'Upload',
  components: {
    PlusOutlined,
    LoadingOutlined
  },
  props: {
    img: {
      type: String,
      default: null
    }
  },
  emits: ['sendUrl'],
  data() {
    return {
      loading: false,
      imageUrl: this.img,
      dataObj: {
        policy: '',
        signature: '',
        key: '',
        ossaccessKeyId: '',
        dir: '',
        host: ''
        // callback:'',
      },
      isUploadImg: false
    }
  },
  watch: {
    'img': {
      handler(val, oval) {
        if (val) {
          this.isUploadImg = false
          this.imageUrl = val
        }
      }
    }
  },
  mounted() {
    if (!this.imageUrl) this.isUploadImg = true
  },
  methods: {
    isUploadimgFun(bool) {
      if (this.imageUrl) {
        this.isUploadImg = bool
      }
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        this.imageUrl = this.dataObj.host + '/' + this.dataObj.key.replace('${filename}', info.file.name)
        this.loading = false
        this.isUploadImg = false
        this.$emit('sendUrl', this.imageUrl)
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!')
        return false
      }
      const _self = this
      return new Promise((resolve, reject) => {
        policy()
          .then(response => {
            _self.dataObj.policy = response.data.policy
            _self.dataObj.signature = response.data.signature
            _self.dataObj.ossaccessKeyId = response.data.accessid
            _self.dataObj.key = response.data.dir + '/' + getUUID() + '_${filename}'
            _self.dataObj.dir = response.data.dir
            _self.dataObj.host = response.data.host
            resolve(true)
          })
          .catch(_ => {
            reject(false)
          })
      })
      // return isJpgOrPng && isLt2M
    }
  }
}
</script>
<style lang='less'>
.avatar-uploader {
  position: relative;
}
.avatar-uploader > .ant-upload {
  width: 83px;
  height: 83px;
  border: none;
  transition: all .2s linear;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0;
}
.avatar {
  width: 83px;
  height: 83px;
}
.ant-upload-select-picture-card .ant-upload-text {
  // margin-top: 8px;
  color: #fff;
}
.img-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #222;
  opacity: 0.7;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
