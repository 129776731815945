<template>
  <div class="GoodsCard" @click="toDetails(goodsInfo)">
    <div class="img-box">
      <img v-lazy="goodsInfo.skuImg || goodsInfo.skuDefaultImg" alt="goods">
    </div>
    <div class="goods-info">
      <div class="goods-info-top">
        <span>{{ goodsInfo.skuTitle ? goodsInfo.skuTitle : '' }}</span>
      </div>
      <div class="goods-info-bottom">
        <div class="goods-info-price">
          <span class="new-price">￥{{ goodsInfo.skuPrice || goodsInfo.price || 0 }}</span>
          <!-- <span class="old-price">￥12999.00</span> -->
        </div>
        <div class="toCar">
          <img src="@/assets/images/icons/select-car.png" alt="car">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoodsCard',
  components: {
  },
  props: {
    goodsInfo: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    toDetails(data) {
      this.$router.push({ path: '/shop/details', query: {
        catalog2Id: data.catalog2Id,
        skuId: data.skuId
      }})
    }
  }
}
</script>
<style lang='less' scoped>
.GoodsCard {
  width: 244px;
  height: 392px;
  transition: all .2s linear;
  cursor: pointer;
  .toCar {
    opacity: 0;
  }

  &:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

    .toCar {
      opacity: 1;
    }
  }

  .img-box {
    width: 244px;
    height: 244px;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  .goods-info {
    height: 148px;
    width: 244px;
    padding: 24px 8px;
    box-sizing: border-box;

    .goods-info-top {
      width: 100%;
      height: 38px;
      color: #666666;
      font-weight: 400;
      margin-bottom: 16px;

      & > span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }

    .goods-info-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .goods-info-price {
      display: flex;
      flex-direction: column;

      .new-price {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 4px;
      }
      .old-price {
        font-size: 12px;
        font-weight: 400;
        color: #DBDBDB;
      }
    }
  }
}
</style>
